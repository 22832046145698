<template>
<div class="container1150">
<header><strong>环保雨水口</strong></header>
<main>
<p>
                环保雨水口是控制城市面源污染的海绵设施，其经过优化设计，在小雨时能够净化初期雨水，大雨时不影响雨水的顺畅排放，具有良好的承重性能，
                高效的雨水净化能力，安装维护便捷等特点
            </p>
<div class="img1"></div>
<h2>产品特性</h2>
<p>
                ● ·
                箱体材质为树脂混凝土，具有强度高、重量轻、抗老化、抗冻及抗化字腐蚀性强、防渗、承载力强等特点
            </p>
<p>
                ● ·
                多层的截污方式、专用的过滤料包和防堵功能设计水，既能离效净化雨，又保证了排水通畅。
            </p>
<div class="img-list">
<div class="img2"></div>
<div class="img3"></div>
</div>
<p>● ·应用广泛，安装灵活</p>
<div class="img4"></div>
<div class="img7"></div>
<div class="img8"></div>
<div class="img9"></div>
<p>● ·产品规格</p>
<div class="img10"></div>

<div class="img6"></div>
<div class="img11"></div>
</main>
</div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .img1 {
            // width: 100%;
            width: 900px;
            height: 400px;
            margin: 10px auto;
            background-image: url("/img/product/1.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img-list {
            display: flex;
            width: 100%;
            .img2 {
                // width: 100%;
                width: 500px;
                height: 500px;
                margin: 10px auto;
                background-image: url("/img/product/3.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img3 {
                // width: 100%;
                width: 550px;
                height: 500px;
                margin: 10px auto;
                background-image: url("/img/product/2.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img4 {
            width: 700px;
            height: 700px;
            margin: 10px auto;
            background-image: url("/img/product/4.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img5 {
            width: 1000px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/5.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img6 {
            width: 1000px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/6.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img7 {
            width: 1100px;
            height: 700px;
            margin: 10px auto;
            background-image: url("/img/product/1-3.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img8 {
            width: 1100px;
            height: 700px;
            margin: 10px auto;
            background-image: url("/img/product/1-4.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img9 {
            width: 1100px;
            height: 700px;
            margin: 10px auto;
            background-image: url("/img/product/1-5.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img10 {
            width: 1000px;
            height: 1000px;
            margin: 10px auto;
            background-image: url("/img/product/1-8.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img11 {
            width: 600px;
            height: 700px;
            margin: 10px auto;
            border: 1px solid #bcbcbc;
            background-image: url("/img/product/1-7.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>